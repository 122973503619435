import React, { ReactNode } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatDate } from '../../pages/BlogPage/dateHelper';
import './BlogCardComponent.css';

interface BlogCardComponentProps {
  backgroundColor?: string;
  textColor?: string;
  url?: string;
  title: string;
  text: string;
  blogShortDescription: string;
  authorName: string;
  authorImage: any;
  blogTags?: string[];
  icon: ReactNode;
  buttonText?: string;
  publishedAt?: string;
  buttonColor?: string;
  alignCenter?: boolean;
  sx?: SxProps<Theme>;
}

const BlogCardComponent: React.FC<BlogCardComponentProps> = ({
  backgroundColor = 'background.paper',
  textColor = 'text.primary',
  url,
  title,
  icon,
  buttonText,
  blogShortDescription,
  authorName,
  blogTags,
  authorImage,
  publishedAt,
  alignCenter = false,
  sx,
}) => {
  return (
    <Card className="card-root" sx={ {borderRadius: '20px',height: '100%',...sx}}>
      <CardContent className={`card-content ${alignCenter ? 'center' : ''}`}>
        <Box
          className="icon-box"
          sx={{
            color: textColor,
            backgroundColor: backgroundColor,
          }}>
          {icon}
        </Box>
        <div className="tags-container">
          {blogTags && blogTags.length > 0 && (
            <Stack direction="row" spacing={1}>
              {blogTags.map((tag: string) => (
                <Typography
                  key={tag}
                  variant="overline"
                  className="tag"
                  sx={{
                    fontSize: '8px',
                  }}
                >
                  {tag}
                </Typography>
              ))}
            </Stack>
          )}
        </div>
        <Typography
          variant="h5"
          className="title"
          sx={{
            color: textColor,
            fontSize:'20px',
            my: 1,
          }}>
          {title}
        </Typography>
        <Typography variant="subtitle2" className="description" sx={{ color: textColor,fontSize: "14px" }}>
          {blogShortDescription}
        </Typography>

        <div className="author-info">
          {authorImage && (
            <Avatar alt={authorImage.fields.name} src={authorImage.fields.file.url} sx={{ width: 30, height: 30 }} />
          )}
          <div className="author-details">
            <Typography variant="subtitle2" className="author-name" sx={{ color: textColor,fontSize: "12px" }}>
              {authorName}
            </Typography>
            <div>
              {publishedAt && (
                <Typography variant="subtitle2" className="published-date" sx={{fontSize: "10px"}}>
                  {formatDate(new Date(publishedAt))}
                </Typography>
              )}
            </div>
          </div>
        </div>

        {url && buttonText && (
          <Button
            variant="outlined"
            href={url}
            className="button"
            sx={{
              color: textColor,
              justifyContent: alignCenter ? 'center' : 'flex-start',
              fontSize: "14px",
            }}>
            {buttonText} {<ArrowForwardIcon fontSize="small" style={{ marginLeft: "8px" }} />}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default BlogCardComponent;
