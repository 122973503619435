import React, { FunctionComponent, useState, useEffect } from 'react';
import { Stack, Typography, Box, Chip, Avatar, CircularProgress, Alert, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import client from './contentfulClient';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { formatDate } from './dateHelper';
import { useTranslation } from 'react-i18next';

interface AssetFields {
  title: string;
  file: {
    url: string;
  };
}

interface EntryFields {
  blogTitle: string;
  blogContent: any;
  slug: string;
  blogBanner?: {
    fields: AssetFields;
  };
  author?: string;
  publishedAt?: string;
  authorName: string;
  authorImage?: {
    fields: AssetFields;
  };
  blogTags?: string[];
}


const Blog: FunctionComponent = () => {
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: string }>();
  const [pageData, setPageData] = useState<EntryFields | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!slug) {
      setError('Invalid slug.');
      return;
    }

    const decodedSlug = decodeURIComponent(slug);
    setLoading(true);
    client
      .getEntries({
        content_type: 'blog',
        'fields.slug': decodedSlug,
      })
      .then((response: any) => {
        if (response.items.length > 0) {
          setPageData(response.items[0].fields);
        } else {
          setError('No blog post found.');
        }
      })
      .catch((err : Error) => {
        console.error(err);
        setError('Failed to load blog post.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Alert severity="error">{error}</Alert>
        <Button variant="contained" color="primary" onClick={() => navigate('/blogList')}>
        {t('blog.backToBlog')}
        </Button>
      </Box>
    );
  }

  if (!pageData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6">{t('blog.noDataAvailable')}</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate('/blogList')}>
        {t('blog.backToBlog')}
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: '2rem',
        maxWidth: '900px',
        margin: '0 auto',
        marginTop: '5rem',
      }}>
      <Typography
        variant="h3"
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          marginBottom: '1.5rem',
        }}>
        {pageData?.blogTitle}
      </Typography>

      {pageData.blogBanner && (
        <Box sx={{ marginBottom: '2rem', textAlign: 'center' }}>
          <img
            src={pageData.blogBanner.fields.file?.url}
            alt={pageData.blogBanner.fields.title}
            style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px' }}
          />
        </Box>
      )}

      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ marginBottom: '1.5rem' }}>
        {pageData.authorImage && (
          <Avatar
            src={pageData.authorImage.fields.file.url}
            alt={pageData.authorName}
            sx={{ width: 40, height: 40 }}
          />
        )}
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 'bold', fontSize: '14px' }}>
            {pageData.authorName}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: 'gray', fontSize: '12px' }}>
            {t('blog.publishedOn')} {formatDate(new Date(pageData.publishedAt!))}
          </Typography>
        </Box>
      </Stack>

      <Box sx={{ marginBottom: '2rem', lineHeight: 1.8 }}>
        {pageData.blogContent &&
          documentToReactComponents(pageData.blogContent)}
      </Box>

      {pageData.blogTags && (
        <Stack direction="row" spacing={1}>
          {pageData.blogTags.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                fontSize: '0.875rem',
                fontWeight: 'bold',
              }}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default Blog;
