import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { IconTextBadge } from '../../components/IconTextBadge';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import ShieldIcon from '../../../assets/images/redesignImages/hand-stars-icon.svg';
import BlogCardComponent from '../../components/CardComponent/BlogCardComponent';
import client from './contentfulClient';
import './BlogList.css';

interface AssetFields {
  title: string;
  file: {
    url: string;
  };
}
interface EntryFields {
  blogTitle: string;
  blogContent: any;
  slug: string;
  blogBanner?: {
    fields: AssetFields;
  };
  author?: string;
  publishedAt?: string;
  authorName: string;
  authorImage?: {
    fields: AssetFields;
  };
  blogTags?: string[];
  pageName?: string;
  blogShortDescription?: string;
  blogThumbnail?: {
    fields: AssetFields;
  };
}

const BlogList: FunctionComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [entries, setEntries] = useState<any[]>([]);

  useEffect(() => {
    client
      .getEntries()
      .then((response: any) => {
        setEntries(response.items);
      })
      .catch((err: Error) => console.log('contentful error ====>', err));
  }, []);

  return (
    <>
      <Stack className="root-stack">
        <Stack className="content-stack">
          <IconTextBadge
            icon={
              <img src={ShieldIcon} alt="hand-icon" className="shield-icon" />
            }
            text={t('blog.badge')}
          />
          <Typography variant="h3" sx={{ marginTop: 2, marginBottom: 2 }}>
            {t('blog.title.part1')}{' '}
            <Box component="span" className="title-box">
              {t('blog.title.part2')}
            </Box>
          </Typography>
        </Stack>
        <Grid container spacing={5} className="grid-container">
          {entries.length > 0 ? (
            entries.map((entry: any, index: number) => {
              const fields: EntryFields = entry.fields;
              return (
                <Grid item xs={12} md={4} key={index}>
                  <BlogCardComponent
                    url={`/blog/${fields.slug}`}
                    title={fields.blogTitle}
                    text={fields.pageName || 'No page name available'}
                    blogTags={fields.blogTags}
                    blogShortDescription={
                      fields.blogShortDescription || 'No description available'
                    }
                    authorImage={fields.authorImage}
                    authorName={fields.authorName}
                    buttonText="Read Article"
                    publishedAt={fields.publishedAt}
                    icon={
                      <img
                        src={fields?.blogThumbnail?.fields?.file?.url || ''}
                        alt={fields?.blogThumbnail?.fields?.title || ''}
                        style={{
                          width: '100%',
                          height: 200,
                          borderRadius: '20px',
                        }}
                      />
                    }
                  />
                </Grid>
              );
            })
          ) : (
            <Typography variant="h6" mt={5}>
              {t('blog.noDataAvailable')}
              </Typography>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default BlogList;
